import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from '../../components/gatsby-image';
import SocialProfile from '../../components/social-profile/social-profile';
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin,
} from 'react-icons/io';
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from './style';

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: 'https://www.facebook.com/Sifrein/',
    tooltip: 'Facebook',
  },
  {
    icon: <IoLogoInstagram />,
    url: 'https://www.instagram.com/galeriesifrein/',
    tooltip: 'Instagram',
  },
  {
    icon: <IoLogoTwitter />,
    url: 'https://twitter.com/sifrein',
    tooltip: 'Twitter',
  },
  // {
  //   icon: <IoLogoLinkedin />,
  //   url: 'https://www.linkedin.com/company/redqinc/',
  //   tooltip: 'Linked In',
  // },
];

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>MENTIONS LÉGALES</h2>
        <p>
<ul>
<li><strong>Raison sociale :</strong>	 	Sifrein</li>
<li><strong>Siège social :</strong> 139 rue du Faubourg Saint-Honoré 75008 Paris	 	</li>
<li><strong>Téléphone :	</strong> +33 1 45 63 31 14</li>
<li><strong>Forme et capital social :</strong>  Société à responsabilité limitée au capital de 1000€	 </li>
<li><strong>Enregistrement CNIL :	</strong> </li>	
<li><strong>RCS :	</strong> 791 265 473 R.C.S. Paris </li>
<li><strong>Code APE :	</strong> 	</li>
<li><strong>Responsable de la publication :	</strong> De Lacombe Jean Sifrein	</li>
<li><strong>Rédaction :	</strong> De Lacombe Jean Sifrei	</li>
<li><strong>Hébergeur :</strong> OVH</li>
<li><strong>Limite de responsabilité :</strong><br/>	
Malgré un contrôle approfondi, nous ne sommes pas responsables des liens externes. Seuls les opérateurs des sites externes sont responsables de leur propre site.
Nous vous rappelons que vous disposez d'un droit d'accès, de modification, de rectification et de suppression des données qui vous concernent (article 34 de la loi "Informatique et Libertés" N° 78-17 du 6 janvier 1978). Pour exercer ce droit, adressez-vous par email au Webmaster. 
</li>
<li>
  <strong>Cookies :</strong><br/>
  Ce site n'utilise pas de Cookies.
</li>
  </ul>          

        </p>
      </AboutPageTitle>

      <AboutImage>
        <GatsbyImage src={Data.avatar.childImageSharp.gatsbyImageData} alt="about" />
      </AboutImage>

      <AboutDetails>
        <h2></h2>
        <p>
        </p>
        <p>
        </p>

        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  );
};

export default About;
