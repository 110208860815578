import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Mentions from '../containers/mentions';

type AboutPageProps = {};

const MentionsL: React.FunctionComponent<AboutPageProps> = () => {
  return (
    <Layout>
      <SEO
        title="La galerie Sifrein Paris"
        description="Fonctionnement, informations de la galerie Sifrein Paris"
      />

      <Mentions />
    </Layout>
  );
};

export default MentionsL;
